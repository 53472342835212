import { DatePicker } from "antd";
import React from "react";
import LabelCom from "../label/LabelCom";

function Date({
  name = null,
  placeholder = "2024-01-31",
  label = "",
  labelOn = "top",
  error = false,
  errorLabel = null,
  labelClassName = "",
  className = "",
  value = null,
  defaultValue = null,
  onChange = null,
  format = "YYYY-MM-DD",
  picker = "date",
  required = false,
  disabled = false,
}) {
  if (onChange) {
    return (
      <LabelCom
        error={error}
        errorLabel={errorLabel}
        labelClassName={labelClassName}
        label={label}
        labelOn={labelOn}
      >
        <DatePicker
          picker={picker}
          format={format}
          className={`date_com ${className}`}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          defaultValue={defaultValue}
          required={required}
          disabled={disabled}
        />
      </LabelCom>
    );
  } else {
    return (
      <LabelCom
        error={error}
        errorLabel={errorLabel}
        labelClassName={labelClassName}
        label={label}
        labelOn={labelOn}
      >
        <DatePicker
          picker={picker}
          format={format}
          className={`date-com ${className}`}
          name={name}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
        />
      </LabelCom>
    );
  }
}

export default Date;
