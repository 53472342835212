import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setDocsModal,
  setStaffValidationState,
} from "../../redux/reducers/globalState";
import {
  getDeceasedStaffsOrStaffById,
} from "../../redux/reducers/globalStateQueries/HrQueries";
import { Collapse, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import TitleDoc from "../../components/titles/TitleDoc";
import Date from "../../components/forms/date/Date";
import { resetLeftMenu } from "../../utils/globalFunction";
import {
  getDeceasedStaffGroups,
  getDepartmentOption,
} from "../../redux/reducers/globalStateQueries/CommonQueries";
import SaveAndClearButtons from "../../components/forms/button/SaveAndClearButtons";
import UploadFilesWithDatas from "../../components/forms/uploadFile/UploadFilesWithDatas";
import { documentFilesValidation } from "../../validations/validationFunction";
import { defaultFormStateFile } from "../../constanst/const";
import TreeSelect from "../../components/forms/treeSelect/TreeSelect";
import Input from "../../components/forms/input/Input";
import { getUpdateAgreements, postUpdateAgreements } from "../../redux/reducers/globalStateQueries/AccountingQueries";
import Button from "../../components/forms/button/Button";


function Agreements() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const staff = useSelector(({ globalState }) => globalState.staff);
  const staffs = useSelector(({ globalState }) => globalState.staffs);
  const user = useSelector(
    ({ MvdFeuLocalStorageState }) => MvdFeuLocalStorageState.user
  );

  const documentTypes = useSelector(
    ({ globalState }) => globalState.documentTypes
  );

  const departmentId = useSelector(
    ({ globalState }) => globalState.departmentId
  );
  // const allowedDepartments = useSelector(
  //   ({ globalState }) => globalState.allowedDepartments
  // );
  const accountingAgreementsData = useSelector(({ globalState }) => globalState.accountingAgreementsData);



  const [tabsKey, setTabsKey] = useState();
  const [formState, setFormState] = useState(defaultFormStateFile);
  // const [searchState, setSearchState] = useState('');

  const [validationError, setValidationError] = useState({
    retention: false,
    label: null,
  });
  const [editItem, setEditItem] = useState(0)
  const onChange = (value, key) => {
    setFormState({
      ...formState,
      [key]: value,
    });
  };


  // const onSave = async (formState) => {
  //   // Validation
  //   if (!formState?.name || !formState?.itn || !formState?.bank_details) {
  //     let errorField = "";

  //     if (!formState?.name || formState.name.length === 0) {
  //       errorField = "name";
  //     } else if (!formState?.itn || formState.itn.length === 0) {
  //       errorField = "itn";
  //     } else if (!formState?.bank_details || formState.bank_details.length === 0) {
  //       errorField = "bank_details";
  //     }

  //     setValidationError({
  //       [errorField]: true,
  //       label: t("validation.empty"),
  //     });
  //     console.log(staff);
  //     return;
  //   }

  //   // Validate file fields in formState
  //   const isValid = await documentFilesValidation(setValidationError, t, formState);
  //   if (!isValid) return;

  //   setValidationError({});

  //   const updatedFormData = {
  //     id: formState.id && formState.id,
  //     name: formState.name,
  //     itn: formState.itn,
  //     bank_details: formState.bank_details,
  //     files: formState.files,
  //     user_id: user.user_id,
  //   };

  //   if (formState.files?.length) {
  //     formState.files.forEach((item) => {
  //       if (item.fileList?.length) {
  //         item.fileList.forEach((file) => {
  //           updatedFormData.document = file.originFileObj;
  //           updatedFormData.doc_types = item.doc_type;
  //           updatedFormData.metadatas = JSON.stringify({ doc_number: item.doc_number }) || "{}";
  //         });
  //       }
  //     });
  //   } else if (formState.doc_id) {
  //     updatedFormData.doc_id = formState.doc_id;
  //   }

  //   // Log final form data
  //   console.log("Final Form Data: ", updatedFormData);

  //   // Dispatch the action to update agreements
  //   dispatch(postUpdateAgreements({ head: updatedFormData, staff_id: staff.id }));
  //   setEditItem(0)
  //   setFormState(defaultFormStateFile);
  //   resetLeftMenu(dispatch);
  // };
  const onSave = async () => {
    if (!formState?.name || !formState?.itn || !formState?.bank_details) {
      setValidationError({
        [!formState.name ? "name" : !formState.itn ? "itn" : !formState.bank_details ? "bank_details" : ""]: true,
        label: t("validation.empty"),
      });
      return;
    } else {
      if (formState.files?.find((el) => el.fileList)) {
        const isValid = await documentFilesValidation(
          setValidationError,
          t,
          formState
        );
        if (!isValid) {
          return;
        }
      }
      setValidationError({});
    }
    const updatedFormData = {
      name: formState.name,
      itn: formState.itn,
      bank_details: formState.bank_details,
      user_id: user.user_id
    }
    const formData = new FormData();
    // formData.append("user_id", user.user_id);
    if (formState.details) {
      formData.append("details", JSON.stringify(formState.details) || "{}");
    }
    if (formState.id) {
      formData.append("id", formState.id);
    }
    if (formState.files?.find((el) => el.fileList)) {
      for (let i = 0; i < formState.files?.length; i++) {
        const item = formState.files?.[i];
        for (let j = 0; j < item?.fileList?.length; j++) {
          formData.append("document", item.fileList[j].originFileObj);
          formData.append("doc_types", item.doc_type);
          formData.append(
            "metadatas",
            JSON.stringify({ doc_number: item.doc_number }) || "{}"
          );
        }
      }
    } else if (formState.doc_id) {
      formData.append("doc_id", formState.doc_id);
    }
    dispatch(postUpdateAgreements({ head: updatedFormData, body: formData, staff_id: staff.id }));
    // dispatch(createOrUpdateuDisabledStaff({body:formData, staffsId:staffs?.map((el) => el.id)}));

    // setFormState(defaultFormStateFile);
    // resetLeftMenu(dispatch);
  };

  const onClear = () => {
    setValidationError({});
    setFormState(defaultFormStateFile);
    resetLeftMenu(dispatch);
  };
  // Получаем данные и состояния из Redux
  const getSearch = (string) => {
    dispatch(getUpdateAgreements(string));
  }

  // Запускаем запрос при загрузке компонента
  // useEffect(() => {
  //   dispatch(getUpdateAgreements());
  // }, []);

  console.log(accountingAgreementsData);

  const items = [
    {
      label: t("document.transfer_of_staff.title_1"),
      key: "1",
      children: (
        <div>
          
          <TitleDoc>{t("accounting.title")}</TitleDoc>
          <div className="flex items-end">
            <Input
              divClassName='px-0 py-[12px] pr-[5px]'
              className='max-w-[700px] w-full min-w-[400px]'
              label={'Поиск по ' + t("passportData.taxPayerId")}
              error={validationError.tin}
              errorLabel={validationError.label}
              mask="999999999"
              placeholder="456887914"
              value={formState?.itn}
              onChange={(e) => onChange(e.target.value, "itn")}
            />
            <button onClick={() => getSearch(formState?.itn)} className='bg-yellow-300 rounded-[5px] py-[5px] px-[10px] mb-[12px]'>
              search
            </button>
          </div>
          {/* <div className="flex gap-[5px] max-w-[500px] flex-wrap"> */}
          {/* {accountingAgreementsData?.map((el, i) => {
              return (
                <div key={i} className="max-w-[730px] w-full py-[10px] px-[5px] rounded-[10px] border grid gap-[7.5px] grid-cols-[30px_160px_150px_90px] bg-white">
                  <p className="flex items-center justify-center">{i}</p>
                  <input type="text" value={el.name} onChange={() => { }} />
                  <input type="text" value={el.itn} onChange={() => { }} />
                  <Button
                    className="max-h-[50px]"
                    onClick={() => {
                      setFormState({
                        ...el,
                        ...defaultFormStateFile,
                      });
                      setTabsKey("2");
                    }}
                  >
                    {t("buttons.change")}
                  </Button>
                </div>
              )
            })} */}

          {/* {accountingAgreementsData?.name ?  (
                <div className="max-w-[730px] w-full py-[10px] px-[5px] rounded-[10px] border grid items-center gap-[7.5px] grid-cols-[30px_160px_150px_90px] bg-white">
                  <p className="flex items-center justify-center">{accountingAgreementsData.id}</p>
                  <input style={{ maxHeight: '30px' }} type="text" value={accountingAgreementsData.name} onChange={() => { }} />
                  <input style={{ maxHeight: '30px' }} type="text" value={accountingAgreementsData.itn} onChange={() => { }} />
                  <Button
                    className="max-h-[50px]"
                    onClick={() => {
                      setFormState({
                        ...accountingAgreementsData,
                        ...defaultFormStateFile,
                      });
                      setTabsKey("2");
                    }}
                  >
                    {t("buttons.change")}
                  </Button>
                </div>
              ) : ''} */}

          {/* </div> */}

          {/* <div className="flex flex-wrap gap-[25px]">
            {accountingAgreementsData?.map((el, i) => {
              return (
                <div className="flex flex-col">
                  <div className="bg-white rounded-[10px]">
                    <Collapse
                      className="w-[500px]"
                      items={[
                        {
                          key: "1",
                          label: `${el?.name || ""}`,
                          children: (
                            <div>
                              <table className="defaultTable">
                                <tr>
                                  <th>{t("document.vacation.flm")}</th>
                                  <td>
                                    {el?.name}
                                  </td>
                                </tr>
                                <tr>
                                  <th>{t("passportData.taxPayerId")}</th>
                                  <td>
                                    {el?.itn}
                                  </td>
                                </tr>
                                <tr>
                                  <th>{t("accounting.bank_details")}</th>
                                  <td>{el?.bank_details || "- - -"}</td>
                                </tr>
                                <tr>
                                  <td>{t("document.document")}</td>
                                  <td>
                                    <button
                                      className="text-blue-500"
                                      onClick={
                                        () =>
                                          dispatch(
                                            setDocsModal({
                                              open: true,
                                              details: {
                                                doc_id: el?.doc_id,
                                              },
                                            })
                                          )
                                        // {}
                                      }
                                    >
                                      {t("document.showDocument")}
                                    </button>
                                  </td>
                                </tr>
                              </table>
                              <div className="flex justify-end w-full">
                                <Button
                                  onClick={() => {
                                    setFormState({
                                      ...el,
                                      ...defaultFormStateFile,
                                    });
                                    setTabsKey("2");
                                    // dispatch(
                                    //   getStaffsOrStaffById({
                                    //     staff_id: el.staff_id,
                                    //   })
                                    // );
                                  }}
                                >
                                  {t("buttons.change")}
                                </Button>
                              </div>
                            </div>
                          ),
                        },
                      ]}
                    />
                  </div>
                </div>
              );
            })}
          </div> */}
          <div className="flex flex-wrap gap-[25px]">
            {accountingAgreementsData?.itn && (
              <div className="flex flex-col">
                <div className="bg-white rounded-[10px]">
                  {/* <Collapse
                    className="w-[500px]"
                    items={[
                      {
                        key: "1",
                        label: `${accountingAgreementsData?.itn || ""}`,
                        children: ( */}
                          <div>
                            <table className="defaultTable">
                              <tr>
                                <th>{t("passportData.taxPayerId")}</th>
                                <td>
                                  {accountingAgreementsData?.itn}
                                </td>
                              </tr>
                              <tr>
                                <th>{t("accounting.label_input")}</th>
                                <td>
                                  {accountingAgreementsData?.name}
                                </td>
                              </tr>
                              <tr>
                                <th>{t("accounting.bank_details")}</th>
                                <td>{accountingAgreementsData?.bank_details || "- - -"}</td>
                              </tr>
                              <tr>
                                <td>{t("document.document")}</td>
                                <td>
                                  <button
                                    className="text-blue-500"
                                    onClick={
                                      () =>
                                        dispatch(
                                          setDocsModal({
                                            open: true,
                                            details: {
                                              doc_id: accountingAgreementsData?.doc_id,
                                            },
                                          })
                                        )
                                      // {}
                                    }
                                  >
                                    {t("document.showDocument")}
                                  </button>
                                </td>
                              </tr>
                            </table>
                            <div className="flex justify-end w-full">
                              <Button
                                onClick={() => {
                                  setFormState({
                                    ...accountingAgreementsData,
                                    ...defaultFormStateFile,
                                  });
                                  setTabsKey("2");
                                  // dispatch(
                                  //   getStaffsOrStaffById({
                                  //     staff_id: el.staff_id,
                                  //   })
                                  // );
                                }}
                              >
                                {t("buttons.change")}
                              </Button>
                            </div>
                          </div>
                        {/* ),
                      }, */}
                    {/* ]} */}
                  {/* /> */}
                </div>
              </div>
            )}
          </div>
        </div>
      ),
    },
    {
      label: t("document.add"),
      key: "2",
      children: (
        <div>
          <TitleDoc>{t("document.add")}</TitleDoc>
          <div>
            <div className="grid grid-cols-[auto_1fr]">
              <div>
                <div className="flex flex-wrap items-center">
                  <Input
                    error={validationError.name}
                    errorLabel={validationError.label}
                    value={formState?.name}
                    onChange={(e) => onChange(e.target.value, "name")}
                    label={t("accounting.label_input")}
                    placeholder={t("accounting.label_input")}
                  />
                  <Input
                    label={t("passportData.taxPayerId")}
                    error={validationError.tin}
                    errorLabel={validationError.label}
                    mask="999999999"
                    placeholder="456887914"
                    value={formState?.itn}
                    onChange={(e) => onChange(e.target.value, "itn")}
                  />
                  <Input
                    placeholder={t("accounting.bank_details")}
                    label={t("accounting.bank_details")}
                    error={validationError.bank_details}
                    errorLabel={validationError.label}
                    value={formState?.bank_details}
                    onChange={(e) => onChange(e.target.value, "bank_details")}
                  />
                  {/* <TreeSelect
                                        label={t("document.department")}
                                        error={validationError.department}
                                        errorLabel={validationError.label}
                                        treeData={allowedDepartments?.map((el) => {
                                        if (el.children) {
                                            return {
                                            label: el.department?.[t("language")],
                                            value: el.id,
                                            children: el.children.map((e) => ({
                                                label: e.department?.[t("language")],
                                                value: e.id,
                                            })),
                                            };
                                        }
                                        return {
                                            label: el.department?.[t("language")],
                                            value: el.id,
                                        };
                                        })}
                                        value={formState.department?.value}
                                        onChange={(e, b) => {
                                        onChange({ label: b[0], value: e }, "department");
                                        }}
                                        labelClassName="w-[300px]"
                                        showSearch={true}
                                        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                                        treeDefaultExpandAll={true}
                                    /> */}
                </div>
                <UploadFilesWithDatas
                  formState={formState}
                  setFormState={setFormState}
                  validationError={validationError}
                  documentTypes={documentTypes}
                  t={t}
                // maxFile={1}
                />
              </div>
            </div>
            <SaveAndClearButtons onSave={() => onSave(formState)} onClear={onClear} t={t} />
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getDeceasedStaffGroups());
    dispatch(getDepartmentOption());
  }, [dispatch]);

  useEffect(() => {
    if (staffs?.map((el) => el.id)) {
      dispatch(getDeceasedStaffsOrStaffById(staffs?.map((el) => el.id)));
    }
  }, [departmentId]);

  return (
    <div className="p-[20px]">
      <div className="">
        <Tabs
          defaultActiveKey="1"
          items={items}
          activeKey={tabsKey}
          onChange={(e) => setTabsKey(e)}
        />
      </div>
    </div>
  );
}

export default Agreements;
